
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'
import { auth, db, storage } from '../../app/firebase.js';
import { collection, addDoc, query, where, getDocs } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';


const AddBusiness = () => {
    const Navigate = useNavigate();

    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)
    const [name, setName] = useState('');
    const [website, setWebsite] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [description, setDescription] = useState('');
    const [category, setCategory] = useState('');
    const [avatar, setAvatar] = useState(null);
    const [address, setAddress] = useState('');
    const [lng, setLng] = useState('');
    const [lat, setLat] = useState('');

    const [city, setCity] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        // Upload avatar image to Firebase Storage
        let avatarUrl = '';
        if (avatar) {
            const storageRef = ref(storage, `avatars/${avatar.name}`);
            try {
                await uploadBytes(storageRef, avatar);
                console.log('Uploaded avatar image successfully');
                avatarUrl = await getDownloadURL(storageRef);
            } catch (error) {
                console.log(error);
                setError('Грешка при качване на аватара');
                setLoading(false);
                return;
            }
        }

        const findCompanyUid = async () => {
            const q = query(collection(db, 'companies'), where('userId', '==', auth.currentUser.uid));
            const querySnapshot = await getDocs(q);
            let companyUid = '';
            querySnapshot.forEach((doc) => {
                companyUid = doc.id;
            });
            return companyUid;
        };

        // Add business to Firebase Firestore
        const business = {
            name,
            website,
            email,
            phone,
            description,
            category,
            avatar: avatarUrl,
            address,
            city,
            lng,
            lat,
            discount: 0,
            rating: 0,
            activeTo: '',
            active: false,
            companyOwner: await findCompanyUid(),
            userUid : auth.currentUser.uid
        };
        await addDoc(collection(db, 'business'), business)
            .then((docRef) => {
                console.log('Added business with ID: ', docRef.id);
                // navigate to business page with my business with success message
                Navigate('/my-business', { state: { message: 'Успешно добавихте бизнеса' } });
            })
            .catch((error) => {
                console.error('Error adding business: ', error);
                setError('Грешка при добавяне на бизнеса');
            });
        setLoading(false);
    };

    const city_list = [
        "Благоевград",
        "Бургас",
        "Варна",
        "Велико Търново",
        "Видин",
        "Враца ",
        "Габрово",
        "Добрич",
        "Кърджали",
        "Кюстендил",
        "Ловеч",
        "Монтана",
        "Пазарджик",
        "Плевен",
        "Перник",
        "Пловдив",
        "Разград",
        "Русе",
        "Силистра",
        "Сливен",
        "Смолян",
        "София",
        "Стара Загора",
        "Търговище",
        "Хасково",
        "Шумен",
        "Ямбол"
    ];

    return (
        <div className='inner-page'>
            <h1>Добавете бизнес</h1>
            <form onSubmit={handleSubmit} className='form-big'>
                <div className='form-input'>
                    <label htmlFor='name'>Име на бизнеса:</label>
                    <input
                        type='text'
                        id='name'
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        
                    />
                </div>
                <div className='form-input'>
                    <label htmlFor='website'>Уебсайт:</label>
                    <input
                        type='text'
                        id='website'
                        value={website}
                        onChange={(e) => setWebsite(e.target.value)}
                        
                    />
                </div>
                <div className='form-input'>
                    <label htmlFor='phone'>Телефон</label>
                    <input
                        type='text'
                        id='phone'
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                        
                    />
                </div>
                <div className='form-input'>
                    <label htmlFor='email'>Имейл:</label>
                    <input
                        type='email'
                        id='email'
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        
                    />
                </div>
                <div className='form-input'>
                    <label htmlFor='description'>Описание:</label>
                    <textarea
                        id='description'
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        
                    />
                </div>
                <div className='form-input'>
                    <label htmlFor='category'>Категория:</label>
                    <select
                        id='category'
                        value={category}
                        onChange={(e) => setCategory(e.target.value)}
                        
                    >
                        <option value=''>Изберете категория</option>
                        <option value='Ветеринарна клиника'>Ветеринарна клиника</option>
                        <option value='Зоо магазин'>Зоо магазин</option>
                        <option value='Фризьорски салон'>Фризьорски салон</option>
                        <option value='Ветеринарен кабинет'>Ветеринарен кабинет</option>
                        <option value='Хотел за кучета'>Хотел за кучета</option>
                        <option value='Приют за кучета'>Приют за кучета</option>
                        <option value='Хотел за хора с домашен любимец'>Хотел за хора с домашен любимец</option>
                        <option value='Услуги (обучение, разходки, фото и др)'>Услуги (обучение, разходки, фото и др)</option>
                        <option value='Online магазин'>Online магазин</option>

                    </select>
                </div>
                <div className='form-input'>
                    <label htmlFor='avatar'>Аватар:</label>
                    <input
                        type='file'
                        id='avatar'
                        accept='image/*'
                        onChange={(e) => setAvatar(e.target.files[0])}
                    />
                </div>
                <div className='form-input'>
                    <label htmlFor='address'>Адрес:</label>
                    <input
                        type='text'
                        id='address'
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                        
                    />
                </div>

                <div className='form-input'>
                    <label htmlFor='category'>Град:</label>
                    <select
                        id='city'
                        value={city}
                        onChange={(e) => setCity(e.target.value)}
                        
                    >
                        <option value=''>Изберете град</option>
                        {
                            city_list.map((city) => {
                                return <option value={city}>{city}</option>
                            })
                        }                        
                    </select>
                </div>
               
                <div className='form-input'>
                    <label htmlFor='lat'>Latitude:</label>
                    <input
                        type='number'
                        id='lat'
                        value={lat}
                        onChange={(e) => setLat(parseFloat(e.target.value))}
                        
                    />
                </div>
                <div className='form-input'>
                    <label htmlFor='lng'>Longitude:</label>
                    <input
                        type='number'
                        id='lng'
                        value={lng}
                        onChange={(e) => setLng(parseFloat(e.target.value))}
                        />
                </div>
                <div style={{ marginTop: '1rem' }}></div>
                {error && <p className='error'>{error}</p>}
                {loading ? 
                    <p>Зареждане....</p>
                    : <button type='submit' className='actionButton'>Добави бизнес</button>
                }
                
            </form>
        </div>
    );
};

export default AddBusiness