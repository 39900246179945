import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';

import { db } from '../app/firebase.js';
import { collection, getDocs, addDoc, deleteDoc, doc } from 'firebase/firestore';
import Moment from 'moment';

const PublicMessages = () => {
    const [data, setData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            const querySnapshot = await getDocs(collection(db, "systemMessages"));
            setData(querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id })));
        };
        fetchData();
    }
        , []);

    const handleDelete = async (id) => {
        const confirmed = window.confirm('Are you sure you want to delete this item?');
        if (!confirmed) return;

        await deleteDoc(doc(db, "systemMessages", id));
        setData(data.filter(item => item.id !== id));
    }

    const addMessage = async ({ title, description }) => {
        const data = {
            title: title,
            description: description,
        }
        await addDoc(collection(db, 'systemMessages'), data);
        // refresh data
        const querySnapshot = await getDocs(collection(db, "systemMessages"));
        setData(querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id })));
    }

    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
        },

    };

    const [modalIsOpen, setIsOpen] = React.useState(false);
    const [title, setTitle] = React.useState('');
    const [description, setDescription] = React.useState('');

    function openModal() {
        setIsOpen(true);
    }

    function closeModal() {
        setIsOpen(false);
    }

    function handleSubmit(e) {
        e.preventDefault();
        addMessage({ title, description });
        setTitle('');
        setDescription('');
        closeModal();
    }

    return (
        <div className='inner-page'>
            <h1>Съобщения към потребителите </h1>
            <div className='inner-page__actions'>
                <button className='actionButton' onClick={openModal}>Добави съобщение</button>
            </div>
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Add message"
            >
                <h2>Добави съобщение</h2>
                <form onSubmit={handleSubmit}>
                    <div className='form-group'>
                        <label htmlFor='title'>Заглавие</label>
                        <input type='text' id='title' value={title} onChange={e => setTitle(e.target.value)} />
                    </div>
                    <div className='form-group'>
                        <label htmlFor='description'>Съобщение</label>
                        <textarea id='description' value={description} onChange={e => setDescription(e.target.value)} />
                    </div>
                    <button className='modal-button' type='submit'>Добави</button>
                </form>
            </Modal>
            <div className='inner-page__content'>
                <table className='table'>
                    <thead>
                        <tr>
                            <th>Заглавие</th>
                            <th>Съобщение</th>
                            <th>Дата</th>
                            <th>Опции</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map(item => (
                            <tr key={item.id}>
                                <td>{item.title}</td>
                                <td>{item.description}</td>
                                <td>{item.createdDate ? Moment(item.createdDate.toDate()).format('DD.MM.yyyy') : 'N/A'}</td>
                                <td>
                                    <button className='actionButton' onClick={() => handleDelete(item.id)}>Изтрии</button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default PublicMessages