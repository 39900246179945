import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom'
import { auth } from '../app/firebase.js'
import { signInWithEmailAndPassword } from 'firebase/auth'
import { useNavigate } from 'react-router-dom';

const Login = () => {
    const [error, setError] = React.useState(null)
    const [loading, setLoading] = React.useState(false)
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault()
        setLoading(true)
        const { email, password } = e.target.elements
        try {
            if(email.value === 'admin@dogmap.bg') {
                await signInWithEmailAndPassword(auth, email.value, password.value)
                // add data to local storage
                localStorage.setItem('user', JSON.stringify(auth.currentUser))
            } else {
                setError('Нямате достъп до тази част от сайта!')
            }
            setLoading(false)
        } catch (error) {
            console.error(error)
            // Handle error (e.g., display an error message)
            setError('Error signing in with password and email!')
            setLoading(false)
        }
    }

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((user) => {
          if (user) {
            navigate('/dashboard');
          }
        });
        return () => {
          unsubscribe(); // Cleanup the listener when the component unmounts
        };
      }, [navigate]);

    return (
        <div>
            <h1>
                Здравейте, моля влезте в профила си!
            </h1>
            <form onSubmit={handleSubmit} className='form'>
                <div className='form-input'>
                    <label htmlFor="email">Email</label>
                    <input type="email" name="email" placeholder="Email" />
                </div>
                <div className='form-input'>
                    <label htmlFor="password">Парола</label>
                    <input type="password" name="password" placeholder="Password" />
                </div>
                {error && <p>{error}</p>}
                {loading && <p>Зареждане...</p>}
                <button className='actionButton margin-top' type="submit">Вход</button>

            </form>

            <p>Направете вашия фирмен профил</p>
            <button
                className='actionButton'
                onClick={() => navigate('/register')}
            >Регистрация</button>
        </div>
    )
}

export default Login