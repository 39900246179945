import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth, db } from '../../app/firebase.js';
import { collection, getDocs, query, updateDoc, where, addDoc, deleteDoc, doc } from 'firebase/firestore';
import Moment from 'moment';

import generateInvoice from '../../functions/generateInvoice.js';

const MyPurchases = () => {
    const Navigate = useNavigate();

    // get state message from AddBusiness.js component and display it
    const [message, setMessage] = useState('');
    const [data, setData] = useState([]);

    useEffect(() => {
        if (window.history.state.usr) {
            setMessage(window.history.state.usr.message);
        }
    }, []);


    useEffect(() => {
        const fetchData = async () => {
            const querySnapshot = await getDocs(
                query(collection(db, "purchases"))
            );
            setData(querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id })));
        };
        fetchData();
    }, []);

    const confirmOrder = async (item) => {
        const confirmed = window.confirm('Are you sure you want to confirm this order?');
        if (!confirmed) return;
        console.log(item);
        const id = item.id;
        const data = {
            status: 'paid',
            invoiceUrl: await generateInvoice(item)
        }

        await updateDoc(doc(db, "purchases", id), data);

        // update business status to active
        const businessId = item.businessUid;
        const businessData = {
            status: 'active',
            activeTo: item.newActiveTo
        }
        await updateDoc(doc(db, "businesses", businessId), businessData);
    }

    return (
        <div className='inner-page'>
            {message && <div className='message'>{message}</div>}
            <h1>Поръчки </h1>
            <div className='inner-page__content'>
                <table className='table'>
                    <thead>
                        <tr>
                            <th>Дата</th>
                            <th>Обект</th>
                            <th>Фирма</th>
                            <th>Подновяване за</th>
                            <th>Цена</th>
                            <th>Статус</th>
                            <th>Документи</th>
                            <th>Опции</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map(item => (
                            <tr key={item.id}>
                                <td>{Moment(item.createdAt.toDate()).format('DD.MM.YYYY')}</td>
                                <td>{item.businessName}</td>
                                <td>{item.companyDetails.companyName}</td>
                                <td>{item.months} месеца</td>
                                <td>{item.price} лв.</td>
                                <td>{item.status == 'pending' ? 'Изчакваща плащане' : item.status == 'paid' ? 'Платена' : 'Отказана' }</td>
                                <td>
                                    {item.status == 'pending' ? <a href={item.proformInvoiceUrl}>Проформа Фактура</a> : item.status == 'paid' ? <a href={item.invoiceUrl}>Фактура</a> : 'Отакзана'}
                                </td>
                                <td>
                                    {item.status == 'pending' &&
                                    <button className='actionButton' onClick={() => confirmOrder(item)}>Потвърди Поръчка</button> }

                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default MyPurchases;