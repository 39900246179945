import { PDFDocument, rgb, StandardFonts } from 'pdf-lib';
import { saveAs } from 'file-saver';
import fontkit from '@pdf-lib/fontkit';
import OpenSans from './OpenSans.ttf';

import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { storage } from "../app/firebase.js";

export default async function generateInvoice(invoice) {
    console.log('invoice', invoice);

    const pdfDoc = await PDFDocument.create();
    pdfDoc.registerFontkit(fontkit);

    // Embed the font using fontkit

    const fontBytes = await fetch(OpenSans).then(res => res.arrayBuffer());
    const font = await pdfDoc.embedFont(fontBytes);

    // Add a page to the document
    const page = pdfDoc.addPage();

    // Get the width and height of the page
    const { width, height } = page.getSize();

    // Draw the header
    const headerFontSize = 18;
    page.drawText('Фактура', {
        x: 50,
        y: height - 2 * headerFontSize,
        size: headerFontSize,
        font,
        color: rgb(0, 0, 0),
    });

    // Draw the original label
    const labelFontSize = 14;
    page.drawText('оригинал', {
        x: 50,
        y: height - 4 * labelFontSize,
        size: labelFontSize,
        font,
        color: rgb(0, 0, 0),
    });

    // Draw the first table with 6 columns and  row
    const table1Data = [
        ['Номер', 'Име на стоката/услугата', 'Цена', 'Общо'],
        ['1', 'Подновяване за ' + invoice.months + 'месеца на ' + invoice.businessName, invoice.price + ' лв', invoice.price + ' лв'],
    ];
    const table1FontSize = 12;
    const table1RowHeight = 20;
    const table1YPosition = height - 25 * labelFontSize;

    // Calculate column widths
    const columnWidths = [width * 0.1, width * 0.5, width * 0.2, width * 0.2];

    table1Data.forEach((row, rowIndex) => {
        row.forEach((cell, colIndex) => {
            page.drawText(cell, {
                x: 50 + columnWidths.slice(0, colIndex).reduce((sum, width) => sum + width, 0),
                y: table1YPosition - rowIndex * table1RowHeight,
                size: table1FontSize,
                font,
                color: rgb(0, 0, 0),
                width: columnWidths[colIndex],
            });
        });
    });

    const total = table1Data.reduce((sum, row) => sum + parseFloat(row[3]), 0);

    // Draw the total text right-aligned
    const totalTextFontSize = 12;
    const totalTextYPosition = table1YPosition - (table1Data.length + 1) * table1RowHeight;
    const totalTextXPosition = width - 110; // Right-aligned position
    page.drawText(`Общо: ${invoice.price } лв`, {
        x: totalTextXPosition,
        y: totalTextYPosition,
        size: totalTextFontSize,
        font,
        color: rgb(0, 0, 0),
        align: 'right', // Align the text to the right
    });


    // Draw the second table with 2 columns and 5 rows
    const table2Data = [
        ['Доставчик ' , 'Получател '],
        ['Име на фирмата: ТОР СИ АП ЕООД', 'Име на фирмата: ' + invoice.companyDetails.companyName],
        ['ЕИК: 206568588', 'ЕИК: ' + invoice.companyDetails.companyEIK],
        ['ДДС No: BG206568588', 'ДДС No: ' + invoice.companyDetails.companyDDSNo],
        ['Град: СОФИЯ', 'Град: ' + invoice.companyDetails.companyCity],
        ['Адрес: жк Надежда 2 бл.246 вх.Д ап.114', 'Адрес: ' + invoice.companyDetails.companyName],
        ['МОЛ: Ивайло Димитров', 'МОЛ: ' + invoice.companyDetails.companyMol],
    ];
    const table2FontSize = 11;
    const table2RowHeight = 20;
    const table2YPosition = height - 8 * labelFontSize;
    table2Data.forEach((row, rowIndex) => {
        row.forEach((cell, colIndex) => {
            page.drawText(cell, {
                x: 50 + colIndex * 300,
                y: table2YPosition - rowIndex * table2RowHeight,
                size: table2FontSize,
                font,
                color: rgb(0, 0, 0),
            });
        });
    });


      // Draw the third table with 2 columns and 4 rows
      const table3Data = [
        ['Начин на плащане:', 'Банков път'],
        ['Банкови реквизити:', 'Уникредит Булбанк, BIC: UNCRBGSF'],
        ['', 'IBAN: BG88UNCR70001522843196 (BGN)'],
    ];
    const table3FontSize = 11;
    const table3RowHeight = 20;
    const table3YPosition = height - 35 * labelFontSize; // Increased Y position
    table3Data.forEach((row, rowIndex) => {
        row.forEach((cell, colIndex) => {
            page.drawText(cell, {
                x: 50 + colIndex * 300,
                y: table3YPosition - rowIndex * table3RowHeight,
                size: table3FontSize,
                font,
                color: rgb(0, 0, 0),
            });
        });
    });

    // Draw the fourth table (left side)
const table4Data = [
    ['Съставил: Ивайло Димитров', ''],
    ['Подпис: ..............', '']
];
const table4FontSize = 11;
const table4RowHeight = 20;
const table4YPosition = height - 45 * labelFontSize; // Increased Y position
table4Data.forEach((row, rowIndex) => {
    row.forEach((cell, colIndex) => {
        page.drawText(cell, {
            x: 50 + colIndex * (width * 0.5), // Adjusted x-coordinate for left side
            y: table4YPosition - rowIndex * table4RowHeight,
            size: table4FontSize,
            font,
            color: rgb(0, 0, 0),
        });
    });
});

// Draw the fifth table (right side)
const table5Data = [
    ['Получател: ' + invoice.companyDetails.companyMol, ''],
    ['Подпис: ..............', '']
];
const table5FontSize = 11;
const table5RowHeight = 20;
const table5YPosition = height - 45 * labelFontSize; // Increased Y position
table5Data.forEach((row, rowIndex) => {
    row.forEach((cell, colIndex) => {
        page.drawText(cell, {
            x: 50 + colIndex * (width * 0.5) + width * 0.5, // Adjusted x-coordinate for right side
            y: table5YPosition - rowIndex * table5RowHeight,
            size: table5FontSize,
            font,
            color: rgb(0, 0, 0),
        });
    });
});

    // random number for invoice number
    const invoiceNumber = Math.floor(Math.random() * 1000000000);
       

    // Serialize the PDFDocument to bytes (a Uint8Array)
    const pdfBytes = await pdfDoc.save();

    // Use file-saver to trigger the browser to download the PDF document
    // saveAs(new Blob([pdfBytes], { type: 'application/pdf' }), 'proforma_invoice.pdf');

    // save in firestore storage and return the url to the document 
    let invoiceUrl = '';
    const storageRef = ref(storage, 'invoices/' + invoice.businessUid + invoiceNumber + '.pdf');
    await uploadBytes(storageRef, pdfBytes);
    invoiceUrl = await getDownloadURL(storageRef);
    
    return invoiceUrl;
}