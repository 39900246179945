import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth, db } from '../../app/firebase.js';
import { collection, getDocs, query, where, addDoc, deleteDoc, doc } from 'firebase/firestore';
import Moment from 'moment';

const MyBusiness = () => {
    // get state message from AddBusiness.js component and display it
    const [message, setMessage] = useState('');

    useEffect(() => {
        if (window.history.state.usr) {
            setMessage(window.history.state.usr.message);
        }
    }, []);

    const [data, setData] = useState([]);
    const Navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            const querySnapshot = await getDocs(
                query(collection(db, "business"))
            );
            setData(querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id })));
        };
        fetchData();
    }, []);

    const handleDelete = async (id) => {
        const confirmed = window.confirm('Are you sure you want to delete this item?');
        if (!confirmed) return;

        await deleteDoc(doc(db, "business", id));
        setData(data.filter(item => item.id !== id));
    }

    return (
        <div className='inner-page'>
            {message && <div className='message'>{message}</div>}
            <h1>Бизнеси </h1>
            <div className='inner-page__actions'>
                <button className='actionButton' onClick={() => Navigate('/add-business')}>Добави Бизнес</button>
            </div>
            <div className='inner-page__content'>
                <table className='table'>
                    <thead>
                        <tr>
                            <th>Име на обект</th>
                            <th>Адрес</th>
                            <th>Град</th>
                            <th>Категория</th>
                            {/* <th>Статус</th> */}
                            {/* <th>Активен до</th> */}
                            <th>Опции</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map(item => (
                            <tr key={item.id}>
                                <td>{item.name}</td>
                                <td>{item.address}</td>
                                <td>{item.city}</td>
                                <td>{item.category}</td>
                                {/* <td>{item.active ? 'Активен' : 'Неактивен'}</td> */}
                                {/* <td
                                    className={item.activeTo && Moment(item.activeTo.toDate()).isBefore(Moment(), 'day') ? 'expired' : ''}
                                >
                                    {item.activeTo ? Moment(item.activeTo.toDate()).format('DD.MM.yyyy') : 'N/A'}
                                </td> */}
                                <td>
                                    {/* {item.activeTo && Moment(item.activeTo.toDate()).isBefore(Moment(), 'day') ?
                                    <button className='actionButton' onClick={() => Navigate(`/renew-business/${item.id}`)}>Поднови</button> : null}
                                    {!item.active ? <button className='actionButton' onClick={() => Navigate(`/renew-business/${item.id}`)}>Плати</button> : null} */}
                                    <button className='actionButton margin-left margin-bottom' onClick={() => Navigate(`/edit-business/${item.id}`)}>Редактирай</button>
                                    <button className='actionButton margin-left margin-bottom' onClick={() => handleDelete(item.id)}>Изтрии</button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default MyBusiness