import { useState, useEffect } from 'react'; 
import { Link, Navigate } from 'react-router-dom';

import { auth } from '../app/firebase.js'
 
const Navigation = () => {
    const currentUser = auth.currentUser;

    const logout = async () => {
        await auth.signOut()
        localStorage.removeItem('user')
        window.open('/', 'noopener,noreferrer');
    }    

    return (
        <div className="navigation">
            <div className="container">
                <nav>
                    <ul>
                        <li><Link to={'/dashboard'}>Табло</Link></li>
                        <li><Link to={'/public-messages'}>Съобщения</Link></li>
                        <li><Link to={'/my-business'}>Бизнеси</Link></li>
                        <li><Link to={'/my-purchases'}>Поръчки</Link></li>
                        <li><Link to={'/'} onClick={logout}>Изход</Link></li>
                    </ul>
                </nav>
            </div>
        </div>
    )
}

export default Navigation